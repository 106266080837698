import React, { useState, useEffect } from "react";
import { getProducts } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
function ProductList() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate();
  const categoryList = [
    {
      title: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      title: "Accessories",
      subCategory: ["Bags", "Stoles", "Caps"],
    },
    {
      title: "Wall Decor",
      subCategory: ["Paintings", "Wall Plates"],
    },
    {
      title: "Handmade",
      subCategory: ["Woven Baskets", "Woven Basckets", "Coasters", "Cup Cozies", "Tea Cozies"],
    },
  ];
  const [activeCat, setActiveCat] = useState("Home Linen");
  const [showSubCategory, setShowSubCategory] = useState(true);
  const [productListArr, setProductListArr] = useState([]);
  const getProductList = async () => {
    try {
      let response = await getProducts();
      if (response.status == 200) {
        console.log(response.data.products);
        setProductListArr(response?.data?.products);
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getProductList();
  }, []);
  return (
    <section id="menu" className="menu">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Products</h2>
          {/* <p>
            Check Our <span>Avirose Collection</span>
          </p> */}
        </div>
        <ul className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay={200}>
          {categoryList?.map((v, i) => {
            return (
              <li className="nav-item">
                <a
                  onClick={() => setActiveCat(v?.title)}
                  className={"nav-link " + (activeCat == v?.title && " active")}
                  data-bs-toggle="tab"
                >
                  <h4>{v?.title}</h4>
                </a>
              </li>
            );
          })}
        </ul>
        <div className="tab-content" data-aos="fade-up" data-aos-delay={300}>
          <div className="tab-pane fade active show" id="#Home-Linen">
            <div className="tab-header text-center">
              <p>Products</p>
              <div className="">
                <h3 onClick={() => setShowSubCategory(!showSubCategory)}>
                  {activeCat}{" "}
                  <i className={"fa ms-2 fa-solid " + (showSubCategory ? " fa-angle-up" : " fa-angle-down")}></i>
                </h3>
              </div>
              <div className="">
                {showSubCategory &&
                  categoryList
                    ?.filter((v) => v.title.replace(/\s+/g, "") === activeCat.replace(/\s+/g, ""))
                    .flatMap((cat) =>
                      cat.subCategory?.map((subCat, i) => (
                        <span
                        style={{cursor:"pointer"}}
                          key={i}
                          className="badge bg-secondary mx-3"
                          onClick={() =>
                            navigate(`/products/${activeCat.replace(/\s+/g, "")}-${subCat.replace(/\s+/g, "")}`)
                          }
                        >
                          {subCat}
                        </span>
                      ))
                    )}
              </div>
            </div>
            <div className="row ">
              {productListArr
                ?.filter((v, i) => {
                  return v?.product_type == activeCat;
                })
                .map((v, i) => {
                  return (
                    <ProductCard v={v} index={i} key={i}/>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductList;
