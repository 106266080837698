import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMobNav, setShowMobNav] = useState(false);
  const navItem = [
    {
      name: "Home",
      icon: "fa fa-home",
      path: "/",
    },
    {
      name: "About",
      icon: "fa fa-info-circle",
      path: "/about",
    },
    {
      name: "Products",
      icon: "fa fa-shopping-cart",
      path: "/products",
    },
    {
      name: "Events",
      icon: "fa fa-laptop",
      path: "/events",
    },
    {
      name: "Our Team",
      icon: "fa fa-users",
      path: "/teams",
    },
    // {
    //   name: "Gallery",
    //   icon: "fa fa-photo",
    //   path: "/gallery",
    // },
    {
        name: "Partners",
      icon: "fa fa-handshake-o",
      path: "/partners",
    },
    {
      name: "Contact Us",
      icon: "fa fa-phone",
      path: "/contact-us",
    },
  ];
  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-end align-items-md-center  justify-content-between">
        <i style={{marginLeft:"-5px"}}
            className={"mobile-nav-toggle  mobile-nav-show bi " + (!showMobNav ? " bi-list" : " bi-x")}
            onClick={() => setShowMobNav(!showMobNav)}
          />
          <div className="">
          <Link to="/" className="logo d-flex  align-items-center me-auto me-lg-0">
            <img src="/assets/img/brandLogo.png" alt />
          </Link>
          </div>
          
          <nav id="navbar" className="navbar">
            <ul>
              {navItem?.map((v, i) => {
                return (
                  <li>
                    <Link to={v?.path} className={location?.pathname == v?.path && " text-dark"}>
                      {" "}
                      <i className={v?.icon}></i>
                      <span className="ms-2">{v?.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <a className="btn-book-a-table text-light bg-success" href="https://wa.me/9434386623" target="blank">
            <span className="d-md-block d-none">Connect On Whatsapp</span>
            <i className="fa fa-whatsapp d-md-none d-block"></i>
          </a>
          {/* <i
            className={"mobile-nav-toggle mobile-nav-show bi " + (!showMobNav ? " bi-list" : " bi-x")}
            onClick={() => setShowMobNav(!showMobNav)}
          /> */}
        </div>
      </header>
      {showMobNav && (
        <div className="vh-100 d-block " style={{ background: "#EFDECD" }}>
          <div className="vh-100 ps-md-5 ps-0 mobNavSlide" style={{ background: "#EFDECD" }}>
            <ul className="mt-5 pt-5">
              {navItem?.map((v, i) => {
                return (
                  <li style={{ listStyle: "none" }} className="mb-4">
                    <div
                      onClick={() => {
                        setShowMobNav(false);
                        navigate(v?.path);
                      }}
                      className={location.pathname == v?.path ? " text-dark" : " text-secondary"}
                    >
                      <i className={v?.icon + " me-2"}></i>
                      {v?.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
