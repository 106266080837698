import React from "react";
import {Link} from "react-router-dom"
function HeroSection() {
  return (
    <section id="hero" className="hero d-flex align-items-center section-bg">
      <div className="container">
        <div className="row justify-content-between gy-5">
          <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
            <h2 data-aos="fade-up">
            Avirose, Inspired By Nature, 
              <br />
              Handcrafted with Love
            </h2>
            <p data-aos="fade-up" data-aos-delay={100}>
              AVIROSE, MEANING, A STRONG WOMAN LEADER IS ALL ABOUT ENABLING A WOMAN TO SET AN ATMOSPHERE OF PEACE AND
              HAPPINESS IN THE HOME SHE RESIDES, AN ENVIRONMENT THAT ALLOWS EVERY FAMILY MEMBER TO HAVE A SENSE OF
              BLISSFUL COMFORT, COMBINED WITH ENTHUSIASM AND PLENTY OF POSITIVE ENERGIES.
            </p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay={200}>
              <Link to="/contact-us" className="btn-book-a-table bg-success">
                Contact Us
              </Link>
              <Link to="/about" className="btn-book-a-table bg-success ms-3">
                Know More
              </Link>
              
            </div>
          </div>
          <div className="col-lg-5 order-1  d-flex justify-content-lg-end marginminus30 order-lg-2 text-center text-lg-start">
            <img src="/assets/img/h4.png" className="img-fluid" alt="img not found" data-aos="zoom-out" data-aos-delay={300} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
