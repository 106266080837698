import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import EventPage from './pages/EventPage';
import TeamPage from './pages/TeamPage';
import GalleryPage from './pages/GalleryPage';
import ContactUsPage from './pages/ContactUsPage';
import {Routes, Route} from "react-router-dom"
import AdminLogin from './pages/AdminLogin';
import Dashboard from "./adminProtectedPage/Dashboard";
import { GlobalStateProvider } from "./GlobalProvider";
import PartnerPage from './pages/PartnerPage';
import ProductBySubCategory from './pages/ProductByCategory';

function App() {  
  return(
    <>
     <GlobalStateProvider>
    <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/products" element={<Products/>}/>
        <Route path="products/:category" element={<ProductBySubCategory/>}/>
        <Route path="/events" element={<EventPage/>}/>
        <Route path="/teams" element={<TeamPage/>}/>
        <Route path="/partners" element={<PartnerPage/>}/>
        <Route path="/gallery" element={<GalleryPage/>}/>
        <Route path="/contact-us" element={<ContactUsPage/>}/>
        <Route path="/admin-login" element={<AdminLogin/>}/>
        <Route path="/admin_dashboard" element={<Dashboard />} />

      </Routes>
      <Footer/>
      </GlobalStateProvider>
    
      </>
  )
  return (
    <div className="App">
     <Navbar/>
     <Routes>
      <Route />
     </Routes>
     {/* <Home/> */}
     {/* <About/> */}
     {/* <Products/> */}
     {/* <EventPage/> */}
{/* <TeamPage/> */}
{/* <GalleryPage/> */}
<ContactUsPage/>
     <Footer/>
    </div>
  );
}

export default App;
