import axios from 'axios';

// Define your API base URL
const BASE_URL = "https://onclicksolution.com/avirosebackend/public/api/";

const user = JSON.parse(localStorage.getItem("manish_design_user"))

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${user?.access_token}`
  },
};


export const userListFunc = async (formData) => {
    try {
      const response = await axios.get(BASE_URL+"get-all-user",config);
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  export const deleteUser = async (id) => {
    try {
      const response = await axios.delete(BASE_URL+"delete-user/"+id, config);
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
};
export const addProducr = async (formData) => {
    try {
      const response = await axios.post(BASE_URL+"add-product",formData, config);
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
};
export const addEvent = async (formData) => {
  try {
    const response = await axios.post(BASE_URL+"add-event",formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const addTeam = async (formData) => {
  try {
    const response = await axios.post(BASE_URL+"add-team",formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const addTestimonial = async (formData) => {
  try {
    const response = await axios.post(BASE_URL+"add-testimonial",formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const editTeam = async (formData, id) => {
  try {
    const response = await axios.post(BASE_URL+"edit-team/"+id,formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const editEvent = async (formData, id) => {
  try {
    const response = await axios.post(BASE_URL+"edit-event/"+id,formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const editProduct = async (formData, id) => {
  try {
    const response = await axios.post(BASE_URL+"edit-product/"+id,formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const editTestimonial = async (formData, id) => {
  try {
    const response = await axios.post(BASE_URL+"edit-testimonial/"+id,formData, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const getProducts  = async () => {
    try {
      const response = await axios.get(BASE_URL+"product-list",config);
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
};
export const getTestimonials  = async () => {
  try {
    const response = await axios.get(BASE_URL+"testimonial-list",config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getEvents  = async () => {
  try {
    const response = await axios.get(BASE_URL+"event-list",config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const getTeams  = async () => {
  try {
    const response = await axios.get(BASE_URL+"team-list",config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const getPartners  = async () => {
  try {
    const response = await axios.get(BASE_URL+"partner-list",config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const deleteProduct = async (id) => {
 
    try {
      const response = await axios.delete(BASE_URL+"delete-product/"+id, config);
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
};
export const deleteTestimonial = async (id) => {
 
  try {
    const response = await axios.delete(BASE_URL+"delete-testimonial/"+id, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const deleteTeam = async (id) => {
 
  try {
    const response = await axios.delete(BASE_URL+"delete-team/"+id, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const deleteEvent = async (id) => {
 
  try {
    const response = await axios.delete(BASE_URL+"delete-event/"+id, config);
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const adminStatics = async (id) => {
    try {
      const response = await axios.get(BASE_URL+"statistics-count");
      return (response);
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error('Error fetching data:', error);
      throw error;
    }
};

export const getGallery = async (id) => {
  try {
    const response = await axios.get(BASE_URL+"gallary");
    return (response);
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error('Error fetching data:', error);
    throw error;
  }
};


