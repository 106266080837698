import React, { useState, useEffect } from "react";
import { getProducts } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
import {useNavigate,useParams, Link} from "react-router-dom"
import ProductCard from "./ProductCard";
function CategoryList() {
    const {category}= useParams();
    // str.replace(/([A-Z])/g, ' $1').trim();
    const paramCat = category.split("-")[0].replace(/([A-Z])/g, ' $1').trim();
    const paramSub = category.split("-")[1].replace(/([A-Z])/g, ' $1').trim();
    
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate()
  const categoryList = [
    {
      title: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      title: "Accessories",
      subCategory: ["Bags", "Stoles", "Caps"],
    },
    {
      title: "Wall Decor",
      subCategory: ["Paintings", "Wall Plates"],
    },
    {
      title: "Handmade",
      subCategory: ["Woven Baskets","Woven Basckets","Coasters", "Cup Cozies", "Tea Cozies"],
    },
  ];
  const [activeCat, setActiveCat] = useState(paramCat);
  const [showSubCategory, setShowSubCategory] = useState(true);
  const [productListArr, setProductListArr] = useState([]);
  const getProductList = async () => {
    try {
      let response = await getProducts();
      if (response.status == 200) {
        console.log(response.data.products);
        setProductListArr(response?.data?.products);
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getProductList();
  }, []);
  return (
    <section id="menu" className="menu">
      <div className="container" data-aos="fade-up">
        
        <div className="tab-content" data-aos="fade-up" data-aos-delay={300}>
          <div className="tab-pane fade active show" id="#Home-Linen">
            <div className="tab-header text-center">
              {/* <p>Products</p> */}
              <div className="d-flex">
                <h4 className="shadow rounded p-2 mb-4 " onClick={() => setShowSubCategory(!showSubCategory)}>
                <Link to="/products">Products</Link>  <i className={"fa mx-2 fa-solid fa-angle-right"}></i> {activeCat} <i className={"fa mx-2 fa-solid fa-angle-right"}></i> {paramSub} 
                  {/* <i className={"fa ms-2 fa-solid " + (showSubCategory ? " fa-angle-up" : " fa-angle-down")}></i> */}
                </h4>
              </div>
              <div className="d-flex">
              {showSubCategory &&
        categoryList
          ?.filter((v) => v.title.replace(/\s+/g, '') === activeCat.replace(/\s+/g, ''))
          .flatMap((cat) =>
            cat.subCategory?.map((subCat, i) => (
              <span style={{cursor:"pointer"}} key={i} className="badge bg-secondary mx-3" onClick={() => navigate(`/products/${activeCat.replace(/\s+/g, '')}-${subCat.replace(/\s+/g, '')}`)}>
                {subCat}
              </span>
            ))
          )}
              </div>
            </div>
            <div className="row ">
              {productListArr
                ?.filter((v, i) => {
                  return v?.product_type == activeCat;
                }).filter((v, i)=>{
                    return v?.product_subcategory == paramSub;
                })
                .map((v, i) => {
                  return (
                    <ProductCard v={v} index={i} key={i}/>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CategoryList;
