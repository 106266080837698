import React, {useEffect} from 'react'
import Events from '../components/Events'

function EventPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className='mt-5'>
        <Events/>
    </div>
  )
}

export default EventPage