import React,{useEffect} from 'react'
import ContactUs from '../components/ContactUs'

function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className='mt-5'>
        <ContactUs/>
    </div>
  )
}

export default ContactUsPage