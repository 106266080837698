import React, { useState, useEffect } from "react";
import { getGallery } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
import MovingBanner from "./MovingBanner";
import Slider from "react-slick";
function Gallery() {
  const [gallaryList, setGalleryList] =useState([])
  const { setGlobalState, globalState } = useGlobalState();
  const getGalleryList = async () => {
    try {
      let response = await getGallery();
      const flattenedArray = [].concat.apply([], response?.data?.events);
      setGalleryList(flattenedArray);
      // console.log(response?.data?.events)
    } catch (error) {}
  };
  useEffect(() => {
    getGalleryList();
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
  };
  var settings2 = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <section id="gallery" className="gallery section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>gallery</h2>
          <p>
            Check <span>Our Gallery</span>
          </p>
        </div>
        
        <Slider {...settings}>
          {gallaryList?.map((v, i) => {
            return (
              <div className="">
                
                    <img src={globalState?.imageBaseUrl+v} className="img-fluid shadow" style={{height:"400px"}} alt />
                  
                {/* </div> */}
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}

export default Gallery;
