import React from 'react'
import ProductList from '../components/ProductList'
import CategoryList from '../components/CategoryList'
function ProductBySubCategory() {
  return (
    <div className='mt-5'>
        <CategoryList/>
    </div>
  )
}

export default ProductBySubCategory