import { useEffect, useState } from "react";
import { addProducr, getProducts, deleteProduct, editProduct, addEvent, editEvent } from "../services/admin.service";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalState } from "../GlobalProvider";
import { getEvents, deleteEvent } from "../services/admin.service";
import "react-toastify/dist/ReactToastify.css";
function EventList({getStaticsCount}) {
  const { setGlobalState, globalState } = useGlobalState();
  const dropDownArr = [
    {
      label: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      label: "Accessories",
      subCategory: ["Bags", "Scarves", "Stoles", "Caps"],
    },
    {
      label: "Wall Decor",
      subCategory: ["Ceramic", "Wall Plates"],
    },
    {
      label: "Miscellaneous Decor",
      subCategory: ["Woven Baskets", "Ipad Covers", "Cup Copie", "Tea Cozies"],
    },
  ];
  const [eventForm, setEventForm] = useState({
    event_title: "",
    event_description: "",
    event_date: "",
    event_venue: "",
    event_image: "",
    event_link: "",
    event_image_opt1: "",
    event_image_opt2: "",
    event_image_opt3: "",
    event_image_opt4: "",
    id: "",
  });
  const handleAddEvent = async () => {
    try {
      const formData = new FormData();
      formData.append("event_title", eventForm.event_title);
      formData.append("event_description", eventForm.event_description);
      formData.append("event_date", eventForm.event_date);
      formData.append("event_venue", eventForm.event_venue);
      formData.append("event_image", eventForm.event_image);
      formData.append("event_link", eventForm.event_link);
      formData.append("event_image_opt1", eventForm.event_image_opt1);
      formData.append("event_image_opt2", eventForm.event_image_opt2);
      formData.append("event_image_opt3", eventForm.event_image_opt3);
      formData.append("event_image_opt4", eventForm.event_image_opt4);
      let response = await addEvent(formData);
      if (response.status == 200) {
        toast.success("Event Added Successfully");
        setEventForm({
          event_title: "",
          event_description: "",
          event_date: "",
          event_venue: "",
          event_image: "",
          event_link: "",
          event_image_opt1: "",
          event_image_opt2: "",
          event_image_opt3: "",
          event_image_opt4: "",
        });
        getEventList();
        getStaticsCount()
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [eventFormType, setEventFormType] = useState("Add");
  const setInputEdit = async (value) => {
    setEventFormType("Edit");
    setEventForm({
      event_title: value?.event_title,
      event_description: value.event_description,
      event_date: value.event_date,
      event_venue: value.event_venue,
      event_image: "",
      event_link: value.event_link,
      event_image_opt1: "",
      event_image_opt2:"",
      event_image_opt3: "",
      event_image_opt4: "",
      id: value.id,
    });
  };
  const handleEditEvent = async () => {
    try {
      const formData = new FormData();
      formData.append("event_title", eventForm.event_title);
      formData.append("event_description", eventForm.event_description);
      formData.append("event_date", eventForm.event_date);
      formData.append("event_venue", eventForm.event_venue);
      formData.append("event_image", eventForm.event_image);
      formData.append("event_link", eventForm.event_link);
      formData.append("event_image_opt1", eventForm.event_image_opt1);
      formData.append("event_image_opt2", eventForm.event_image_opt2);
      formData.append("event_image_opt3", eventForm.event_image_opt3);
      formData.append("event_image_opt4", eventForm.event_image_opt4);
      if (eventForm.event_image) {
        formData.append("event_image", eventForm.event_image);
      }
      if (eventForm.event_image_opt1) {
        formData.append("event_image_opt1", eventForm.event_image_opt1);
      }
      if (eventForm.event_image_opt2) {
        formData.append("event_image_opt2", eventForm.event_image_opt2);
      }
      if (eventForm.event_image_opt3) {
        formData.append("event_image_opt3", eventForm.event_image_opt3);
      }
      if (eventForm.event_image_opt4) {
        formData.append("event_image_opt4", eventForm.event_image_opt4);
      }

      let response = await editEvent(formData, eventForm.id);
      if (response?.status == 200) {
        toast.success("Event updated successfully!");
        setEventForm({
          event_title: "",
          event_description: "",
          event_date: "",
          event_venue: "",
          event_image: "",
          event_link: "",
          event_image_opt1: "",
          event_image_opt2: "",
          event_image_opt3: "",
          event_image_opt4: "",
        });
        getEventList();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [eventList, setEventList] = useState([]);
  const getEventList = async () => {
    try {
      let response = await getEvents();
      setEventList(response.data.events);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteEvent = async (id) => {
    try {
      // Show confirmation prompt
      const isConfirmed = window.confirm("Are you sure you want to delete this Event?");

      // If user confirms, proceed with deletion
      if (isConfirmed) {
        let response = await deleteEvent(id);
        if (response.status === 200) {
          toast.success("Event Deleted Successfully");
          getEventList();
          getStaticsCount()
        }
      } else {
        // User canceled the deletion
        toast.info("Event deletion canceled");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getEventList();
  }, []);
  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-secondary mb-4">
          <i className="fa fa-calendar"></i> Events
        </h4>
        <button
          type="button"
          className="btn shadow btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            setEventFormType("Add");
            setEventForm({
              event_title: "",
              event_description: "",
              event_date: "",
              event_venue: "",
              event_image: "",
              event_link: "",
              event_image_opt1: "",
              event_image_opt2: "",
              event_image_opt3: "",
              event_image_opt4: "",
            });
          }}
        >
          Add Event
        </button>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">S.no</th>
            <th scope="col">Event</th>
            <th scope="col">Date</th>
            <th scope="col">Venue</th>

            <th scope="col">
              <div className="d-flex justify-content-end">
                <div>Action</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {eventList?.map((v, i) => {
            return (
              <tr>
                <th scope="row" className="pt-4">
                  {i + 1}
                </th>
                <td>
                  <div className="d-flex">
                    <div className="provileAvtar">
                      <img src={globalState.imageBaseUrl + v?.event_image} />
                    </div>
                    <div className="ms-2 my-auto">
                      <h6 className="mb-0"> {v?.event_title}</h6>
                      <p className="text-secondary mb-0 fontMono">{v?.event_description}</p>
                      <p className="text-primary mb-0 fontMono">
                        <a target="blank" className="text-primary" href={v?.event_link}>
                          <i className="fa fa-eye me-1"></i>
                          <span>View</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </td>
                <td className="pt-4">{v?.event_date}</td>
                <td className="pt-4">{v?.event_venue}</td>

                <td className="pt-4">
                  <div className="d-flex justify-content-end">
                    {/* <i className="fa fa-photo" onClick={() => handleDeleteEvent(v?.id)}></i> */}
                    <i
                      type="button"
                      className="fa fa-edit mx-2"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => setInputEdit(v)}
                    ></i>
                    <i className="fa fa-trash" onClick={() => handleDeleteEvent(v?.id)}></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content container">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {eventFormType} Event
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {/* {eventFormType === "Edit" && (
                <>
                  <img
                    style={{ height: "50px" }}
                    src={
                      eventForm?.event_image == ""
                        ? globalState.imageBaseUrl + eventForm?.event_image
                        : URL.createObjectURL(eventForm?.event_image)
                    }
                  />
                  <br />
                </>
              )} */}
              <div className="row">
                <div className="col-md-6 col-12">
                  <label>{eventFormType}Event Image</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => {
                      setEventForm({ ...eventForm, event_image: e.target.files[0] });
                    }}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <label>Event Title*</label>
                  <input
                    className="form-control mb-2"
                    value={eventForm.event_title}
                    onChange={(e) => setEventForm({ ...eventForm, event_title: e.target.value })}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label>Event Date*</label>
                  <input
                    className="form-control mb-2"
                    type="date"
                    value={eventForm.event_date}
                    onChange={(e) => setEventForm({ ...eventForm, event_date: e.target.value })}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label>Event Venue*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={eventForm.event_venue}
                    onChange={(e) => setEventForm({ ...eventForm, event_venue: e.target.value })}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label>Event Link*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={eventForm.event_link}
                    onChange={(e) => setEventForm({ ...eventForm, event_link: e.target.value })}
                  />
                </div>
                <div className=" col-12">
                  <label>Event Discription*</label>
                  <textarea
                    className="form-control mb-2"
                    value={eventForm.event_description}
                    onChange={(e) => setEventForm({ ...eventForm, event_description: e.target.value })}
                  />
                </div>
                <div className=" col-12 mx-0 mt-3 p-0 row">
                  <div className="col-md-3 col-12 m-0 border shadow-lg p-2">
                    <input type="file" className="form-control" onChange={(e) => {
                      setEventForm({ ...eventForm, event_image_opt1: e.target.files[0] });
                    }}/>
                    <label>Option Image 1</label>
                  </div>
                  <div className="col-md-3 col-12 m-0 border shadow-lg p-2">
                    <input type="file" className="form-control" onChange={(e) => {
                      setEventForm({ ...eventForm, event_image_opt2: e.target.files[0] });
                    }}/>
                    <label>Option Image 2</label>
                  </div>
                  <div className="col-md-3 col-12 m-0 border shadow-lg p-2">
                    <input type="file" className="form-control" onChange={(e) => {
                      setEventForm({ ...eventForm, event_image_opt3: e.target.files[0] });
                    }}/>
                    <label>Option Image 3</label>
                  </div>
                  <div className="col-md-3 col-12 m-0 border shadow-lg p-2">
                    <input type="file" className="form-control" onChange={(e) => {
                      setEventForm({ ...eventForm, event_image_opt4: e.target.files[0] });
                    }}/>
                    <label>Option Image 4</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {eventFormType == "Add" ? (
                <button type="button" className="btn btn-primary" onClick={handleAddEvent}>
                  Save
                </button>
              ) : (
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleEditEvent}>
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EventList;
