import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { getTestimonials, getTeams } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
function OurTeam() {
  const { setGlobalState, globalState } = useGlobalState();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [testimonialListArr, setTestimonialListArr] = useState([]);
  const getTestimonialsList = async () => {
    try {
      let response = await getTestimonials();
      if (response.status == 200) {
        setTestimonialListArr(response?.data?.testimonials);
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };

  const StarRating = (number) => {
    console.log(number);
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<i key={i} className={i < number ? "bi bi-star-fill" : "fa fa-star-o"} aria-hidden="true"></i>);
    }

    return <div className="star-container">{stars}</div>;
  };
  const [teamArr, setTeamArr] = useState([]);
  const getTeamList = async () => {
    try {
      let response = await getTeams();
      if (response.status == 200) {
        setTeamArr(response?.data?.teams);
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getTestimonialsList();
    getTeamList();
  }, []);
  return (
    <section id="chefs" className="chefs section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Team</h2>
          
        </div>
        <div className="row gy-4 justify-content-center">
          {teamArr?.map((v, i) => {
            return (
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={(i + 1) * 100}
              >
                <div className="chef-member">
                  <div className="member-img">
                    <img src={globalState?.imageBaseUrl+v?.member_image} style={{height:"500px"}} className="img-fluid" alt="not provided" />
                    {/* <div className="social">
                      <a href>
                        <i className="bi bi-twitter" />
                      </a>
                      <a href>
                        <i className="bi bi-facebook" />
                      </a>
                      <a href>
                        <i className="bi bi-instagram" />
                      </a>
                      <a href>
                        <i className="bi bi-linkedin" />
                      </a>
                    </div> */}
                  </div>
                  <div className="member-info">
                    <h4>{v?.member_name}</h4>
                    <span>{v?.member_role}</span>
                    <p>
                     {v?.member_description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}

          
        </div>
        
      </div>
    </section>
  );
}

export default OurTeam;
