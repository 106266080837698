import React from "react";

function WhyChooseUs() {
  const data = [
    {
      title: "About The Founder",
      para: `Myself, Ritu Gupta, a nature lover, am a Doctorate in Botany and during the excursions related to my research work, have closely observed the flora of the North- Eastern Himalayas. The designs created by me reflect a strong influence of the North-Eastern Himalayan landscape,as well as its rich variety of flora and fauna.  
      Before I started my entrepreneurial journey in 2022, I had spent a significant part of my life in the North-Eastern region of  India, moving from  Jalpaiguri, Siliguri, Kalimpong, and Darjeeling. The pristine mountains and the smiling happy people of the region left an unforgettable imprint on my mind . It is here that I found my adopted mother Meera Tamang, under whose loving care my twin boys grew up. My project AVIROSE is my tribute to her and all the people of the North-East, the people who are as beautiful and pure-hearted as nature’s bounty of this region.`,
    },
    {
      title: "ABOUT THE BRAND",
      para: "The brand  AVIROSE can best be described by the tagline, ‘Simply Sophisticated’. We do not use complicated motifs, strong colors or rough fabrics. Our products are in harmony with peaceful happy surroundings. We believe that true elegance lies in simplicity ,and quality should always triumph over quantity..Our philosophy emphasizes clean lines,uncluttered spaces and a neutral colour palette,allowing the inherent beauty of materials and design to shine through .Nature forms the core of all our  products, designed with the aim of making our home and office spaces feel  inviting and harmonious.",
    },
    {
      title: "Purpose",
      para: "Avirose products are created with the aim to provide a sustainable livelihood to the local women artisans and to promote and preserve their embroidery craftsmanship. The home linen items have been made with the purpose of creating happy spaces that make us feel close to nature.",
    },
    // {
    //   title: "GOING BY OUR TAGLINE",
    //   para: `"`,
    // },
  ];
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container" data-aos="fade-up">
        <h3 className="text-center mb-5">
          <i>OUR STORY</i>
        </h3>
        <div className="row gy-4 ">
          {data.map((v, i) => {
            return (
              <div className="col-lg-12  "  data-aos="fade-up" data-aos-delay={100}>
                <div className="why-box shadow-lg whyBoxBg"  >
                  <h4>
                    <i>{v?.title.toLocaleUpperCase()}</i>
                  </h4>
                  <p>{v?.para}</p>

                  {/* <div className="text-center">
                <a href="#" className="more-btn">
                  Learn More <i className="bx bx-chevron-right" />
                </a>
              </div> */}
                </div>
              </div>
            );
          })}
          <div className="footer-links  py-3 text-dark">
            <h4>GET IN TOUCH</h4>
            <div className="social-links d-flex">
              <a
                target="_blank"
                href="https://www.instagram.com/ritugupta4479?igsh=MWl0a3p0NmY5M2tkbA=="
                className="twitter text-dark me-3"
              >
                <i className="bi bi-twitter" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/ritugupta4479?igsh=MWl0a3p0NmY5M2tkbA=="
                className="facebook text-dark me-3"
              >
                <i className="bi bi-facebook" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/ritugupta4479?igsh=MWl0a3p0NmY5M2tkbA=="
                className="instagram text-dark me-3"
              >
                <i className="bi bi-instagram" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/ritugupta4479?igsh=MWl0a3p0NmY5M2tkbA=="
                className="linkedin text-dark me-3"
              >
                <i className="bi bi-linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
