import React, { useEffect, useState } from "react";
import { getEvents } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
import EventCard from "./EventCard";

function Events() {
  const { setGlobalState, globalState } = useGlobalState();
  const [eventList, setEventList] = useState([]);
  const getEventList = async () => {
    try {
      let response = await getEvents();
      setEventList(response.data.events);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEventList();
  }, []);
  return(
    <section id="chefs" className="chefs section-bg">
    <div className="container" data-aos="fade-up">
    <div className="section-header">
          <h2>Events</h2>
          {/* <p>
            Sharing <span>Our Moments</span> At Avirose
          </p> */}
        </div>
      <div className="row gy-4 justify-content-center">
        {eventList?.map((v, i) => {
          return (
            <EventCard v={v} i={i}/>
          );
        })}

        
      </div>
      
    </div>
  </section>
  )
  return (
    <section id="events" className="events">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-header">
          <h2>Events</h2>
          <p>
            Sharing <span>Our Moments</span> At Avirose
          </p>
        </div>
        <div className="" data-aos="fade-up" data-aos-delay={100}>
          {/* <div className="row  ">
            {eventList.map((v, i) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 p-0 m-0" data-aos-delay={(i + 1) * 300}>
                  <div className=" p-3 rounded shadow">
                    <img
                      className="img-fluid w-100"
                      src={globalState.imageBaseUrl+v?.event_image}
                    />
                    <div className="">
                        <div>
                        <h4 className="text-secondary mb-0" ><b>{v?.event_title}</b></h4>
                        <p className="text-secondary mb-0" style={{fontSize:"12px"}} >{v?.event_description.substring(0, 100)}<b className="text-dark">{v?.event_description.length >100 && "..."}</b></p>
                        
                        </div>
                      </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="swiper-pagination" /> */}
          <div className="row gy-4">
          {eventList?.map((v, i) => {
            return (
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={(i + 1) * 100}
              >
                <div className="chef-member">
                  <div className="member-img">
                    <img src={globalState?.imageBaseUrl+v?.event_image} className="img-fluid" alt="not provided" />
                    {/* <div className="social">
                      <a href>
                        <i className="bi bi-twitter" />
                      </a>
                      <a href>
                        <i className="bi bi-facebook" />
                      </a>
                      <a href>
                        <i className="bi bi-instagram" />
                      </a>
                      <a href>
                        <i className="bi bi-linkedin" />
                      </a>
                    </div> */}
                  </div>
                  <div className="member-info">
                    <h4>{v?.event_title}</h4>
                    {/* <span>{v?.member_role}</span>
                    <p>
                     {v?.member_description}
                    </p> */}
                  </div>
                </div>
              </div>
            );
          })}

          
        </div>
        </div>
      </div>
    </section>
  );
}

export default Events;
