import React, {useState, useEffect} from "react";
import CountUp from "react-countup";
import {adminStatics} from "../services/admin.service"
function CounterSection() {
  const [staticData, setStaticData]= useState()
    const getStaticsCount = async ()=>{
      try {
        let response = await adminStatics();
        if(response.status==200){
          setStaticData(response.data)
        }
      } catch (error) {
        // toast.error("Something went wrong")
      }
    }
    useEffect(()=>{
        getStaticsCount()
    }, [])
  return (
    <section id="stats-counter" className="stats-counter">
      <div className="container" data-aos="zoom-out">
        <div className="row gy-4">
        <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              {/* <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" /> */}
              <CountUp end={staticData?.total_products} />
              <p>Products</p>
            </div>
          </div>
          
          {/* End Stats Item */}
          
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              {/* <span data-purecounter-start={0} data-purecounter-end={1453} data-purecounter-duration={1} className="purecounter" /> */}
              <CountUp end={staticData?.total_events} />
              <p>Events</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp end={staticData?.total_teams} />
              {/* <span data-purecounter-start={0} data-purecounter-end={32} data-purecounter-duration={1} className="purecounter" /> */}
              <p>Members</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <CountUp end={staticData?.total_testimonials} />

              {/* <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" /> */}
              <p>Testimonials</p>
            </div>
          </div>
          {/* End Stats Item */}
        </div>
      </div>
    </section>
  );
}

export default CounterSection;
