import React,{useEffect} from "react";
import HeroSection from "../components/HeroSection";
import CounterSection from "../components/CounterSection";
import ProductList from "../components/ProductList";
import Testimonials from "../components/Testimonials";
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <HeroSection />
      {/* <CounterSection /> */}
      <ProductList />
      <Testimonials />
    </div>
  );
}

export default Home;
