import React from "react";
import { useGlobalState } from "../GlobalProvider";
import Slider from "react-slick";

function ProductCard({ v, index }) {
  const { globalState } = useGlobalState();
  const modalId = `exampleModal-${index}`; // Unique modal ID based on the index

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <div className="col-lg-3" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
        <div className="mx-1 my-3 rounded cardHover shadow bg-light">
          <img
            src={globalState.imageBaseUrl + v?.product_image}
            style={{ height: "250px", width: "100%", padding: "10px" }}
            className="menu-img img-fluid"
            alt="Image not provided"
          />
          <div className="bg-light ms-2 p-1">
            <div>
              <p className="text-secondary mb-0">
                <b>{v?.product_name}</b>
              </p>
              <p className="text-secondary mb-0" style={{ fontSize: "12px" }}>
                {v?.product_material}
              </p>
              <p className="text-secondary mb-0" style={{ fontSize: "12px" }}>
                {v?.product_dimension}
              </p>
              <p className="text-secondary" style={{ fontSize: "12px" }}>
                {v?.product_description.substring(0, 100)}
                <b className="text-dark">...</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={modalId}
        tabIndex={-1}
        aria-labelledby={`${modalId}-label`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`${modalId}-label`}>
                {v?.product_name}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body px-5">
              <Slider {...settings}>
                <img src={globalState.imageBaseUrl + v?.product_image_opt} className="d-block w-100" alt="..." style={{height:"500px"}}/>
                <img src={globalState.imageBaseUrl + v?.product_image} className="d-block w-100" alt="..." style={{height:"500px"}}/>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
