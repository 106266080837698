import React,{useEffect} from 'react'
import OurTeam from '../components/OurTeam'

function TeamPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className='mt-5'>
        <OurTeam/>
    </div>
  )
}

export default TeamPage