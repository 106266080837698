import React, {useEffect} from 'react'
import ProductList from '../components/ProductList'

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className='mt-5'>
        <ProductList/>
    </div>
  )
}

export default Products