import { useEffect, useState } from "react";
import { addProducr, getProducts, deleteProduct, editProduct, getTeams ,editTeam, deleteEvent,addTeam, deleteTeam} from "../services/admin.service";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalState } from "../GlobalProvider";
import "react-toastify/dist/ReactToastify.css";
function TeamList({getStaticsCount}) {
  const { setGlobalState, globalState } = useGlobalState();
  const dropDownArr = [
    {
      label: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      label: "Accessories",
      subCategory: ["Bags", "Scarves", "Stoles", "Caps"],
    },
    {
      label: "Wall Decor",
      subCategory: ["Ceramic", "Wall Plates"],
    },
    {
      label: "Miscellaneous Decor",
      subCategory: ["Woven Baskets", "Ipad Covers", "Cup Copie", "Tea Cozies"],
    },
  ];
  const [teamForm, setTeamForm] = useState({
    member_name: "",
    member_image: "",
    member_role: "",
    member_description:"",
  });
  const handleAddTeam = async () => {
    try {
      const formData = new FormData();
      formData.append("member_name", teamForm.member_name);
      formData.append("member_image", teamForm.member_image);
      formData.append("member_role", teamForm.member_role);
      formData.append("member_description", teamForm.member_description);
      let response = await addTeam(formData);
      if (response.status == 200) {
        toast.success("Team Added Successfully");
        setTeamForm({
            member_name: "",
            member_image: "",
            member_role: "",
            member_description:"",
        });
        getTeamList();
        getStaticsCount()
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [teamFormType, setTeamFormType] = useState("Add");
  const setInputEdit = async (value) => {
    setTeamFormType("Edit");
   
    setTeamForm({
        member_name: value.member_name,
        member_image: "",
        member_role:value.member_role,
        member_description:value.member_description,
        id:value.id
    });
  };
  const handleEditTeam = async () => {
    try {
      const formData = new FormData();
      formData.append("member_name", teamForm.member_name);
      formData.append("member_role", teamForm.member_role);
      formData.append("member_description", teamForm.member_description);
      if (teamForm.member_image) {
        formData.append("member_image", teamForm.member_image);
      }
      
      let response = await editTeam(teamForm, teamForm.id);
      if (response?.status == 200) {
        toast.success("Team updated successfully!");
        setTeamForm({
            member_name: "",
            member_image: "",
            member_role: "",
            member_description:"",
        });
        getTeamList();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [teamListArr, setTeamListArr] = useState([]);
  const getTeamList = async () => {
    try {
      let response = await getTeams();
      if (response.status == 200) {
        setTeamListArr(response?.data?.teams);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handleDeleteTeam = async (id) => {
    try {
      // Show confirmation prompt
      const isConfirmed = window.confirm("Are you sure you want to delete the team member?");

      // If user confirms, proceed with deletion
      if (isConfirmed) {
        let response = await deleteTeam(id);
        if (response.status === 200) {
          toast.success("Member Deleted Successfully");
          getTeamList();
          getStaticsCount()
        }
      } else {
        // User canceled the deletion
        toast.info("Member deletion canceled");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getTeamList();
  }, []);
  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-secondary mb-4">
          <i className="fa fa-users"></i> Teams
        </h4>
        <button
          type="button"
          className="btn shadow btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            setTeamFormType("Add");
            setTeamForm({
                member_name: "",
                member_image: "",
                member_role: "",
                member_description:"",
            });
          }}
        >
          Add Member
        </button>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">S.no</th>
            <th scope="col">Avatar</th>
            <th scope="col">Description</th>
            <th scope="col">
              <div className="d-flex justify-content-end">
                <div>Action</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {teamListArr?.map((v, i) => {
            return (
              <tr>
                <th scope="row" className="pt-4">
                  {i + 1}
                </th>
                <td>
                  <div className="d-flex">
                    <div className="provileAvtar">
                      <img src={globalState.imageBaseUrl + v?.member_image} />
                    </div>
                    <div className="ms-2 my-auto">
                      <h6 className="mb-0">{v?.member_name}</h6>
                      <p className="text-secondary mb-0 fontMono">{v?.member_role}</p>
                    </div>
                  </div>
                </td>
                <td className="pt-4">{v?.member_description}</td>
                
                <td className="pt-4">
                  <div className="d-flex justify-content-end">
                    {/* <i className="fa fa-eye"></i> */}
                    <i
                      type="button"
                      className="fa fa-edit mx-2"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => setInputEdit(v)}
                    ></i>
                    <i className="fa fa-trash" 
                    
                    onClick={() => handleDeleteTeam(v?.id)}
                    ></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        
      >
        <div className="modal-dialog">
          <div className="modal-content container">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {teamFormType} Member
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {teamFormType === "Edit" && (
                <>
                  {/* <img
                    style={{ height: "50px" }}
                    src={
                      productForm?.product_image == ""
                        ? globalState.imageBaseUrl + productForm?.product_image_Prev
                        : URL.createObjectURL(productForm?.product_image)
                    }
                  /> */}
                  <br />
                </>
              )}
              <div className="row">
              <div className="col-12">
              <label>{teamFormType} Member Image</label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => {
                  setTeamForm({ ...teamForm, member_image: e.target.files[0] });
                }}
              />
              </div>
              
              <div className="col-12">
              <label>Member Name*</label>
              <input
                className="form-control mb-2"
                value={teamForm.member_name}
                onChange={(e) => setTeamForm({ ...teamForm, member_name: e.target.value })}
              />
              </div>
              <div className="col-12">
                  <label>Role*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={teamForm.member_role}
                    onChange={(e) => setTeamForm({ ...teamForm, member_role: e.target.value })}
                  />
                </div>
                <div className="col-12">
                  <label>Description*</label>
                  <textarea
                    className="form-control mb-2"
                    type="text"
                    value={teamForm.member_description}
                    onChange={(e) => setTeamForm({ ...teamForm, member_description: e.target.value })}
                  />
                </div>
              
             
              </div>
              
              
              
              
              
              
              
            </div>
            <div className="modal-footer">
              {teamFormType == "Add" ? (
                <button type="button" className="btn btn-primary"
                 onClick={handleAddTeam}
                 >
                  Save
                </button>
              ) : (
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                 onClick={handleEditTeam}
                 >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TeamList;
