import { useEffect, useState } from "react";
import { addProducr, getProducts, deleteProduct, editProduct } from "../services/admin.service";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalState } from "../GlobalProvider";
import "react-toastify/dist/ReactToastify.css";
function ProductList({ getStaticsCount }) {
  const { setGlobalState, globalState } = useGlobalState();
  const [searchKey, setSearchKey] = useState("");
  const dropDownArr = [
    {
      label: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      label: "Accessories",
      subCategory: ["Bags", "Scarves", "Stoles", "Caps"],
    },
    {
      label: "Wall Decor",
      subCategory: ["Ceramic", "Wall Plates"],
    },
    {
      label: "Miscellaneous Decor",
      subCategory: ["Woven Baskets", "Ipad Covers", "Cup Copie", "Tea Cozies"],
    },
  ];
  const [productForm, setProductForm] = useState({
    product_name: "",
    product_image: "",
    product_type: "",
    product_subcategory: "",
    material: "",
    weight: "",
    dimension: "",
    color: "",
    straplength: "",
    product_image_opt: "",
    product_description: "",
    actual_price: "",
    product_image_Prev: "",
    product_image_opt_prev: "",
    id: "",
  });
  const handleAddProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("product_name", productForm.product_name);
      formData.append("product_image", productForm.product_image);
      formData.append("product_type", productForm.product_type);
      formData.append("product_material", productForm.material);
      formData.append("product_weight", productForm.weight);
      formData.append("product_dimension", productForm.dimension);
      formData.append("product_color", productForm.color);
      formData.append("product_straplength", productForm.straplength);
      formData.append("product_image_opt", productForm.product_image_opt);
      formData.append("product_description", productForm.product_description);
      // formData.append("actual_price", productForm.actual_price);
      formData.append("product_subcategory", productForm.product_subcategory);
      // formData.append("discount_price", productForm.discount_price);
      let response = await addProducr(formData);
      if (response.status == 200) {
        toast.success("Product Added Successfully");
        setProductForm({
          product_name: "",
          product_description: "",
          product_type: "",
          product_image: "",
          actual_price: "",
          material: "",
          weight: "",
          dimension: "",
          color: "",
          straplength: "",
          product_image_opt: "",
          product_subcategory: "",
        });
        getProductList();
        getStaticsCount();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [productFormType, setProductFormType] = useState("Add");
  const setInputEdit = async (value) => {
    setProductFormType("Edit");
    setProductForm({
      product_name: value.product_name,
      product_description: value.product_description,
      product_type: value.product_type,
      product_image: "",
      product_image_Prev: value.product_image,
      material: value.product_material,
      actual_price: value.actual_price,
      weight: value.product_weight,
      product_image_opt: "",
      product_image_opt_prev: value?.product_image_opt,
      product_subcategory: value?.product_subcategory,
      dimension: value.product_dimension,
      color: value.product_color,
      straplength: value.product_straplength,
      id: value?.id,
    });
    console.log(productForm?.product_image_opt_prev)
  };
  const handleEditProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("product_name", productForm.product_name);
      formData.append("product_type", productForm.product_type);
      // formData.append("actual_price", productForm.actual_price);
      formData.append("discount_price", productForm.discount_price);
      formData.append("product_description", productForm.product_description);
      formData.append("product_weight", productForm.weight);
      formData.append("product_dimension", productForm.dimension);
      formData.append("product_color", productForm.color);
      formData.append("product_material", productForm.material);
      formData.append("product_subcategory", productForm.product_subcategory);
      formData.append("product_straplength", productForm.straplength);
      if (productForm.product_image) {
        formData.append("product_image", productForm.product_image);
      }
      if (productForm.product_image_opt) {
        formData.append("product_image_opt", productForm.product_image_opt);
      }
      let response = await editProduct(formData, productForm.id);
      if (response?.status == 200) {
        toast.success("Product updated successfully!");
        setProductForm({
          product_name: "",
          product_description: "",
          product_type: "",
          product_image: "",
          actual_price: "",
          material: "",
          weight: "",
          dimension: "",
          color: "",
          straplength: "",
          product_image_opt: "",
          product_subcategory: "",
        });
        getProductList();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [productListArr, setProductListArr] = useState([]);
  const getProductList = async () => {
    try {
      let response = await getProducts();
      if (response.status == 200) {
        console.log(response.data.products);
        setProductListArr(response?.data?.products);
        setSearchResult(response?.data?.products)
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handleDeleteProduct = async (id) => {
    try {
      // Show confirmation prompt
      const isConfirmed = window.confirm("Are you sure you want to delete this Product?");

      // If user confirms, proceed with deletion
      if (isConfirmed) {
        let response = await deleteProduct(id);
        if (response.status === 200) {
          toast.success("Product Deleted Successfully");
          getProductList();
          getStaticsCount();
        }
      } else {
        // User canceled the deletion
        toast.info("Product deletion canceled");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getProductList();
  }, []);
  const[searchResult, setSearchResult]=useState([])
  const setSearchedValue = (key) => {
    setSearchKey(key);
    if (key.length > 0) {
      setSearchResult(productListArr?.filter((v) => {
        return v.product_name.toLowerCase().includes(key.toLowerCase());
      }));
    } else {
      setSearchResult(productListArr);
    }
  };
  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center ">
        <h4 className="text-secondary d-flex mb-0">
          <i className="fa fa-cube"></i> <span className="ms-2">Products</span>
        </h4>
        <div className="mx-5 w-100">
          <input
            className="form-control w-100"
            onChange={(e) => setSearchedValue(e.target.value)}
            placeholder="Search Products"
          />
        </div>
        <div style={{ width: "25%" }}>
          <button
            type="button"
            className="btn shadow btn-primary w-100"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              setProductFormType("Add");
              setProductForm({
                product_name: "",
                product_description: "",
                product_type: "",
                product_image: "",
                actual_price: "",
                material: "",
                weight: "",
                dimension: "",
                color: "",
                straplength: "",
                product_image_opt: "",
                product_subcategory: "",
              });
            }}
          >
            Add Product
          </button>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">S.no</th>
            <th scope="col">Product Avatar</th>
            <th scope="col">Product Type</th>
            <th scope="col">Subcategory</th>
            {/* <th scope="col">Discounted Price</th> */}
            <th scope="col">
              <div className="d-flex justify-content-end">
                <div>Action</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {searchResult?.map((v, i) => {
            return (
              <tr>
                <th scope="row" className="pt-4">
                  {i + 1}
                </th>
                <td>
                  <div className="d-flex">
                    <div className="provileAvtar">
                      <img src={globalState.imageBaseUrl + v?.product_image} />
                    </div>
                    <div className="ms-2 my-auto">
                      <h6 className="mb-0">{v?.product_name}</h6>
                      <p className="text-secondary mb-0 fontMono">{v?.product_description}</p>
                    </div>
                  </div>
                </td>
                <td className="pt-4">{v?.product_type}</td>
                <td className="pt-4">{v?.product_subcategory}</td>
                {/* <td className="pt-4">{v?.actual_price} INR</td>
                <td className="pt-4">{v?.discount_price} INR</td> */}
                <td className="pt-4">
                  <div className="d-flex justify-content-end">
                    {/* <i className="fa fa-eye"></i> */}
                    <i
                      type="button"
                      className="fa fa-edit mx-2"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => setInputEdit(v)}
                    ></i>
                    <i className="fa fa-trash" onClick={() => handleDeleteProduct(v?.id)}></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content container">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {productFormType} Product
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {productFormType === "Edit" && (
                <>
                  <img
                    style={{ height: "50px" }}
                    src={
                      productForm?.product_image == ""
                        ? globalState.imageBaseUrl + productForm?.product_image_Prev
                        : URL.createObjectURL(productForm?.product_image)
                    }
                  />
                  <br />
                </>
              )}
              {(productFormType === "Add" && productForm?.product_image) && (
                <>
                  <img
                    style={{ height: "50px" }}
                    src={
                      URL.createObjectURL(productForm?.product_image)
                    }
                  />
                  <br />
                </>
              )}
              <div className="row">
                <div className="col-md-6 col-12">
                  <label>{productFormType} Product Image</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => {
                      setProductForm({ ...productForm, product_image: e.target.files[0] });
                    }}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <label>Product Name*</label>
                  <input
                    className="form-control mb-2"
                    value={productForm.product_name}
                    onChange={(e) => setProductForm({ ...productForm, product_name: e.target.value })}
                  />
                </div>
                {/* <div className="col-md-6 col-12">
                  <label>Price*</label>
                  <input
                    className="form-control mb-2"
                    type="number"
                    value={productForm.actual_price}
                    onChange={(e) => setProductForm({ ...productForm, actual_price: e.target.value })}
                  />
                </div> */}
                <div className="col-md-6 col-12">
                  <label>Material*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={productForm.material}
                    onChange={(e) => setProductForm({ ...productForm, material: e.target.value })}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Dimension*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={productForm.dimension}
                    onChange={(e) => setProductForm({ ...productForm, dimension: e.target.value })}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Color*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={productForm.color}
                    onChange={(e) => setProductForm({ ...productForm, color: e.target.value })}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Strap Length*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={productForm.straplength}
                    onChange={(e) => setProductForm({ ...productForm, straplength: e.target.value })}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Weight*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={productForm.weight}
                    onChange={(e) => setProductForm({ ...productForm, weight: e.target.value })}
                  />
                </div>
                <div className="col-md-6 col-12">
                {productFormType === "Edit" && (
                <>
                  <img
                    style={{ height: "50px" }}
                    src={
                      productForm?.product_image_opt == ""
                        ? globalState.imageBaseUrl + productForm?.product_image_opt_prev
                        : URL.createObjectURL(productForm?.product_image_opt)
                    }
                  />
                  <br />
                </>
              )}
              {(productFormType === "Add" && productForm?.product_image_opt) && (
                <>
                  <img
                    style={{ height: "50px" }}
                    src={
                      URL.createObjectURL(productForm?.product_image_opt)
                    }
                  />
                  <br />
                </>
              )}
                  <label>Product Other Image</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => {
                      setProductForm({ ...productForm, product_image_opt: e.target.files[0] });
                    }}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Product Type*</label>
                  <select
                    id="productType"
                    className="form-control mb-2"
                    value={productForm.product_type}
                    onChange={(e) => setProductForm({ ...productForm, product_type: e.target.value })}
                  >
                    <option value="">Select Type</option>
                    {dropDownArr.map((v, i) => {
                      return <option value={v.label}>{v.label}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-12">
                  <label>Sub Category*</label>
                  <select
                    id="productType"
                    className="form-control mb-2"
                    value={productForm.product_subcategory}
                    onChange={(e) => setProductForm({ ...productForm, product_subcategory: e.target.value })}
                  >
                    <option value="">Select Type</option>
                    {productForm?.product_type &&
                      dropDownArr
                        ?.filter((v) => v.label === productForm?.product_type)
                        .map((cat) => cat.subCategory?.map((subCat, i) => <option value={subCat}>{subCat}</option>))}
                  </select>
                </div>
              </div>

              <label>Product Discription*</label>
              <textarea
                className="form-control mb-2"
                value={productForm.product_description}
                onChange={(e) => setProductForm({ ...productForm, product_description: e.target.value })}
              />
            </div>
            <div className="modal-footer">
              {productFormType == "Add" ? (
                <button type="button" className="btn btn-primary" onClick={handleAddProduct}>
                  Save
                </button>
              ) : (
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleEditProduct}>
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ProductList;
