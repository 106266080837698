import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { getTestimonials, getTeams, getPartners } from "../services/admin.service";
import { useGlobalState } from "../GlobalProvider";
function OurPartners() {
  const { setGlobalState, globalState } = useGlobalState();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  

  
  const [partnerArr, setPartnerArr] = useState([]);
  const getPartnersList = async () => {
    try {
      let response = await getPartners();
      if (response.status == 200) {
        setPartnerArr(response?.data?.partners);
      }
    } catch (error) {
      // toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getPartnersList();
  }, []);
  return (
    <section id="chefs" className="chefs section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Partners</h2>
          
        </div>
        <div className="row gy-4 justify-content-center">
          {partnerArr?.map((v, i) => {
            return (
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={(i + 1) * 100}
              >
                <div className="chef-member">
                  <div className="member-img">
                    <img src={globalState?.imageBaseUrl+v?.partner_image} style={{height:"400px"}} className="img-fluid" alt="not provided" />
                    {/* <div className="social">
                      <a href>
                        <i className="bi bi-twitter" />
                      </a>
                      <a href>
                        <i className="bi bi-facebook" />
                      </a>
                      <a href>
                        <i className="bi bi-instagram" />
                      </a>
                      <a href>
                        <i className="bi bi-linkedin" />
                      </a>
                    </div> */}
                  </div>
                  <div className="member-info">
                    <h4>{v?.partner_name}</h4>
                   <a href={v?.partner_link} target="_blank"><span><b>Visit Website</b></span></a> 
                   <p className="mb-0">
                   <i className="fa fa-address-card"></i> {v?.partner_address}
                    </p>
                    <p>
                     {v?.partner_description}
                    </p>
                    
                  </div>
                </div>
              </div>
            );
          })}

          
        </div>
        
      </div>
    </section>
  );
}

export default OurPartners;
