import React,{useEffect} from 'react'
import OurPartners from '../components/OurPartners'

function PartnerPage() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }, []);
  return (
    <div className='mt-5'>
        <OurPartners/>
    </div>
  )
}

export default PartnerPage