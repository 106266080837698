import React from "react";

function AboutSection() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>About Us</h2>
          {/* <p>
            Learn More <span>About Us</span>
          </p> */}
        </div>
        <div className="row gy-4">
          <div
            className="col-lg-7 position-relative about-img"
            style={{ backgroundImage: "url(/assets/img/aboutUs1.jpg)" , backgroundSize:"100%, 100%", backgroundRepeat:"no-repeat"}}
            data-aos="fade-up"
            data-aos-delay={150}
          >
            {/* <div className="call-us position-absolute">
              <h4>Contact Us</h4>
              <p><a href="tel:+91 9434386623" className="text-success">+91 9434386623</a></p>
            </div> */}
          </div>
          <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay={300}>
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                Before I started my entrepreneurial journey in 2022, I had spent a significant part of my life in the
                North-Eastern region of India, moving from Jalpaiguri, Siliguri, Kalimpong, and Darjeeling. The pristine
                mountains and the smiling happy people of the region left an unforgettable imprint on my mind . It is
                here that I found my adopted mother Meera Tamang, under whose loving care my twin boys grew up. My
                project AVIROSE is my tribute to her and all the people of the North-East, the people who are as
                beautiful and pure-hearted as nature’s bounty of this region.
              </p>
              {/* <ul>
                <li>
                  <i className="bi bi-check2-all" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </li>
                <li>
                  <i className="bi bi-check2-all" /> Duis aute irure dolor in reprehenderit in voluptate velit.
                </li>
                <li>
                  <i className="bi bi-check2-all" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                  pariatur.
                </li>
              </ul>
              <p>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident
              </p> */}
              <div className="position-relative mt-4">
                <img src="assets/img/aboutUs2.jpeg" className="img-fluid" alt />
                {/* <a href="https://www.facebook.com/100064732718856/posts/838598358307914/?mibextid=bgAApDAIfEsnpeLk" target="_blank" className="glightbox play-btn" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
