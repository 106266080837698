import { useEffect, useState } from "react";
import { getTestimonials, getTeams,addTestimonial ,editTeam, deleteEvent,addTeam, deleteTeam,deleteTestimonial, editTestimonial} from "../services/admin.service";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalState } from "../GlobalProvider";
import "react-toastify/dist/ReactToastify.css";
function Testimonial({getStaticsCount}) {
  const { setGlobalState, globalState } = useGlobalState();
  const dropDownArr = [
    {
      label: "Home Linen",
      subCategory: ["Cushion Cover", "Table Linen", "Sheer Curtains", "Bath Linen", " Bed Linen"],
    },
    {
      label: "Accessories",
      subCategory: ["Bags", "Scarves", "Stoles", "Caps"],
    },
    {
      label: "Wall Decor",
      subCategory: ["Ceramic", "Wall Plates"],
    },
    {
      label: "Miscellaneous Decor",
      subCategory: ["Woven Baskets", "Ipad Covers", "Cup Copie", "Tea Cozies"],
    },
  ];
  const [testimonialForm, setTestimonialForm] = useState({
    user_name: "",
    user_address: "",
    user_rating: "",
    user_review:"",
  });
  const handleAddTestimonial = async () => {
    try {
      const formData = new FormData();
      formData.append("user_name", testimonialForm.user_name);
      formData.append("user_address", testimonialForm.user_address);
      formData.append("user_rating", testimonialForm.user_rating);
      formData.append("user_review", testimonialForm.user_review);
      let response = await addTestimonial(formData);
      if (response.status == 200) {
        toast.success("Testimonial Added Successfully");
        setTestimonialForm({
            user_name: "",
    user_address: "",
    user_rating: "",
    user_review:"",
        });
        getTestimonialsList();
        getStaticsCount()
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [testimonialFormType, setTestimonialFormType] = useState("Add");
  const setInputEdit = async (value) => {
    setTestimonialFormType("Edit");
   
    setTestimonialForm({
        user_name: value?.user_name,
    user_address: value.user_address,
    user_rating: value.user_rating,
    user_review:value.user_review,
        id:value.id
    });
  };
  const handleEditTestimonial = async () => {
    try {
      const formData = new FormData();
      formData.append("user_name", testimonialForm.user_name);
      formData.append("user_address", testimonialForm.user_address);
      formData.append("user_review", testimonialForm.user_review);
      formData.append("user_rating", testimonialForm.user_rating);
      
      
      let response = await editTestimonial(testimonialForm, testimonialForm.id);
      if (response?.status == 200) {
        toast.success("Testimonial updated successfully!");
        setTestimonialForm({
            user_name: "",
    user_address: "",
    user_rating: "",
    user_review:"",
        });
        getTestimonialsList();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const [testimonialListArr, setTestimonialListArr] = useState([]);
  const getTestimonialsList = async () => {
    try {
      let response = await getTestimonials();
      if (response.status == 200) {
        setTestimonialListArr(response?.data?.testimonials);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handleDeleteTestimonial = async (id) => {
    try {
      // Show confirmation prompt
      const isConfirmed = window.confirm("Are you sure you want to delete this testimonial?");

      // If user confirms, proceed with deletion
      if (isConfirmed) {
        let response = await deleteTestimonial(id);
        if (response.status === 200) {
          toast.success("Testimonial Deleted Successfully");
          getTestimonialsList();
          getStaticsCount()
        }
      } else {
        // User canceled the deletion
        toast.info("Testimonial deletion canceled");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getTestimonialsList();
  }, []);
  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-secondary mb-4">
          <i className="fa fa-star"></i> Testimonials
        </h4>
        <button
          type="button"
          className="btn shadow btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            setTestimonialFormType("Add");
            setTestimonialForm({
                user_name: "",
                user_address: "",
                user_rating: "",
                user_review:"",
            });
          }}
        >
          Add Testimonial
        </button>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">S.no</th>
            <th scope="col">Name</th>
            <th scope="col">Review</th>
            <th scope="col">Address</th>
            <th scope="col">
              <div className="d-flex justify-content-end">
                <div>Action</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {testimonialListArr?.map((v, i) => {
            return (
              <tr>
                <th scope="row" className="pt-4">
                  {i + 1}
                </th>
                <td>
                  <div className="d-flex">
                    <div className="provileAvtar">
                      {/* <img src={globalState.imageBaseUrl + v?.member_image} /> */}
                    </div>
                    <div className="ms-2 my-auto">
                      <h6 className="mb-0">{v?.user_name}</h6>
                      <p className="text-secondary mb-0 fontMono">{v?.user_rating}</p>
                    </div>
                  </div>
                </td>
                <td className="pt-4">{v?.user_review}</td>
                <td className="pt-4">{v?.user_address}</td>
                
                <td className="pt-4">
                  <div className="d-flex justify-content-end">
                    {/* <i className="fa fa-eye"></i> */}
                    <i
                      type="button"
                      className="fa fa-edit mx-2"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => setInputEdit(v)}
                    ></i>
                    <i className="fa fa-trash" 
                    
                    onClick={() => handleDeleteTestimonial(v?.id)}
                    ></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        
      >
        <div className="modal-dialog">
          <div className="modal-content container">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {testimonialFormType} Testimonial
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {testimonialFormType === "Edit" && (
                <>
                  {/* <img
                    style={{ height: "50px" }}
                    src={
                      productForm?.product_image == ""
                        ? globalState.imageBaseUrl + productForm?.product_image_Prev
                        : URL.createObjectURL(productForm?.product_image)
                    }
                  /> */}
                  <br />
                </>
              )}
              <div className="row">
              
              
              <div className="col-12">
              <label>User Name*</label>
              <input
                className="form-control mb-2"
                value={testimonialForm.user_name}
                onChange={(e) => setTestimonialForm({ ...testimonialForm, user_name: e.target.value })}
              />
              </div>
              <div className="col-12">
                  <label>Rating*</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    value={testimonialForm.user_rating}
                    onChange={(e) => setTestimonialForm({ ...testimonialForm, user_rating: e.target.value })}
                  />
                </div>
                <div className="col-12">
                  <label>Review*</label>
                  <textarea
                    className="form-control mb-2"
                    type="text"
                    value={testimonialForm.user_review}
                    onChange={(e) => setTestimonialForm({ ...testimonialForm, user_review: e.target.value })}
                  />
                </div>
                <div className="col-12">
                  <label>Address*</label>
                  <textarea
                    className="form-control mb-2"
                    type="text"
                    value={testimonialForm.user_address}
                    onChange={(e) => setTestimonialForm({ ...testimonialForm, user_address: e.target.value })}
                  />
                </div>
                
              
             
              </div>
              
              
              
              
              
              
              
            </div>
            <div className="modal-footer">
              {testimonialFormType == "Add" ? (
                <button type="button" className="btn btn-primary"
                 onClick={handleAddTestimonial}
                 >
                  Save
                </button>
              ) : (
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                 onClick={handleEditTestimonial}
                 >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Testimonial;
